<template>
  <TablePage ref="tablePage" v-model="options" />
</template>

<script>
export default {
  components: {
    TablePage: () => import("@/components/tablePage"),
  },
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
};
</script>